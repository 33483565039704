'use client';
import React, { useContext, useEffect, useState } from 'react';
import image from '../../public/images/error.jpg';
import Image from 'next/image';
import Link from 'next/link';
import useLayoutData from '@/hooks/LayoutData';
import useFetchContent from '@/hooks/Content';
import { ErrorContext, ErrorContextProps } from '@/context/ErrorContext';
import { NextSeo } from 'next-seo';

function Error({ statusCode }: any) {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useLayoutData();
  useFetchContent();

  const { errorData } = useContext<ErrorContextProps>(ErrorContext);

  const homepageURL = errorData?.find((l) => l.name === 'homepage');
  const faqURL = errorData?.find((l) => l.name === 'FAQ');

  if (!mounted) return null;

  return (
    <p>
      {statusCode ? (
        <>
          <NextSeo title="404 Not Found - Gazelle" />
          <div className="w-full flex flex-col text-center justify-center">
            <div className="flex w-full justify-center">
              <Image
                src={image}
                alt={`An error ${statusCode} occurred on server`}
                className="w-[390px] h-[148px] lg:w-[530px] lg:h-[209px]"
              />
            </div>
            <div className="py-4">
              <div className="text-[40px] mb-4 font-bold">
                <span className="hidden lg:flex lg:justify-center font-helvetica-neue">
                  {' '}
                  Oops! You caught us sleeping
                </span>
                <span className="flex font-helvetica-neue justify-center text-[28px] lg:hidden">
                  {' '}
                  Oops!{' '}
                </span>
                <span className="flex font-helvetica-neue justify-center text-[28px] lg:hidden">
                  You caught us sleeping
                </span>
              </div>
              <p className="lg:text-[24px] my-2 font-thin text-[17px]">
                The page you’re looking for was not found.
              </p>
              <p className="lg:text-[24px] font-thin text-[17px]">
                Try visiting our
                <Link
                  href={homepageURL?.link ?? ''}
                  className=" text-[#FF6900] pl-1 pr-1"
                >
                  homepage
                </Link>
                or{' '}
                <Link
                  href={faqURL?.link ?? ''}
                  className="pl-1 pr-1 text-[#FF6900]"
                >
                  FAQ
                </Link>{' '}
                section for more help.
              </p>
            </div>
          </div>
        </>
      ) : (
        'An error occurred on client'
      )}
    </p>
  );
}

Error.getInitialProps = ({ res, err }: any) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;
